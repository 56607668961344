import { searchActions, searchGetters, searchMutations } from './types'
import { SEARCH_PAGE_LIMIT } from '../constants'

import { sdk } from 'Services/shelfNetworkSdk'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

const loneSdkCall = new LoneSdkCall()

export default {
  async [searchActions.LOAD_LIST] ({ commit }, opts = {}) {
    commit(searchMutations.SET_IS_LOADING, true)

    const { data, fetchNext } = await loneSdkCall.takeLatest(
      sdk.frontOffice.getLotsPage({
        ...opts,
        page: { limit: SEARCH_PAGE_LIMIT }
      })
    )

    commit(searchMutations.SET_LIST, data)
    commit(searchMutations.SET_FETCH_NEXT, fetchNext)

    commit(searchMutations.SET_IS_LOADING, false)
  },

  async [searchActions.LOAD_MORE] ({ getters, commit }) {
    const fetchNext = getters[searchGetters.FETCH_NEXT]
    if (!fetchNext) return

    commit(searchMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } = await loneSdkCall.takeLatest(
      fetchNext()
    )
    commit(searchMutations.PUSH_LIST, data)
    commit(searchMutations.SET_FETCH_NEXT, newFetchNext)

    commit(searchMutations.SET_IS_NEXT_LOADING, false)
  },
}
