import { LotFrontOffice } from 'Models/LotFrontOffice'
import { searchGetters } from './types'

export default {
  [searchGetters.IS_LOADING]: state => state.isLoading,
  [searchGetters.IS_NEXT_LOADING]: state => state.isNextLoading,
  [searchGetters.FETCH_NEXT]: state => state.fetchNext,
  [searchGetters.LIST]: state => state.list
    .map(item => new LotFrontOffice(item)),
}
