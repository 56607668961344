import { searchMutations } from './types'

export default {
  [searchMutations.SET_IS_LOADING]: (state, val) => {
    state.isLoading = val
  },

  [searchMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [searchMutations.SET_LIST]: (state, val) => {
    state.list = val
  },

  [searchMutations.PUSH_LIST]: (state, val) => {
    state.list.push(...val)
  },

  [searchMutations.SET_FETCH_NEXT] (state, value) {
    state.fetchNext = value
  },
}
